.stepWarp-box {
  min-height: 420px;
  padding-top: 80px;
}
.stepWarp {
  padding: 50px 0 30px 60px;
  display: flex;
  background-color: #fff;
  height: 420px;
  overflow: hidden;
}
.stepWarp .stepLi {
  width: 12.5%;
  height: 150px;
  border-bottom: 1px solid rgba(138, 136, 137, 0.5);
  position: relative;
}
.stepWarp .stepLi .info {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-50%, 50%);
  opacity: 0;
}
.stepWarp .stepLi .progressBox {
  background-color: #fff;
  padding: 10px;
}
.stepWarp .stepLi .line {
  position: absolute;
  border-left: 1px dotted #C4C4C4;
  height: 50px;
  left: 50%;
  top: -45px;
  transform: translateX(-50%);
}
.stepWarp .stepLi .line .sub-circle {
  content: '';
  width: 6px;
  height: 6px;
  background-color: blue;
  position: absolute;
  top: -8px;
  left: -3px;
  border-radius: 50%;
}
.stepWarp .stepLi .label {
  position: absolute;
  top: -81px;
  width: 88px;
  left: -12px;
  font-size: 16px;
  color: #6A6A6A;
  text-align: center;
}
.stepWarp .stepLi .iconWarp {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -152px;
  left: -3px;
}
.stepWarp .stepLi:last-child {
  border: none;
}
.stepWarp .stepLi:nth-child(1) .info {
  animation: progressLower 0.5s;
  animation-fill-mode: forwards;
}
.stepWarp .stepLi:nth-child(2) .info {
  animation: progressUpper 0.5s 0.5s;
  animation-fill-mode: forwards;
}
.stepWarp .stepLi:nth-child(3) .info {
  animation: progressLower 0.5s 1s;
  animation-fill-mode: forwards;
}
.stepWarp .stepLi:nth-child(4) .label {
  left: -8px;
}
.stepWarp .stepLi:nth-child(4) .info {
  animation: progressUpper 0.5s 1.5s;
  animation-fill-mode: forwards;
}
.stepWarp .stepLi:nth-child(5) .info {
  animation: progressLower 0.5s 2s;
  animation-fill-mode: forwards;
}
.stepWarp .stepLi:nth-child(6) .info {
  animation: progressUpper 0.5s 2.5s;
  animation-fill-mode: forwards;
}
.stepWarp .stepLi:nth-child(7) .info {
  animation: progressLower 0.5s 3s;
  animation-fill-mode: forwards;
}
.stepWarp .stepLi:nth-child(8) .info {
  animation: progressUpper 0.5s 3.5s;
  animation-fill-mode: forwards;
}
.stepWarp .lowerStep {
  transform: rotate(180deg) translateY(-150px);
}
.stepWarp .lowerStep .info {
  left: 100%;
  top: 100%;
  transform: translate(-50%, -50%) rotate(-180deg);
  bottom: auto;
}
.stepWarp .lowerStep .line {
  top: 55px;
}
.stepWarp .lowerStep .line .sub-circle {
  top: auto;
  bottom: -8px;
}
.stepWarp .lowerStep .label {
  top: auto;
  bottom: -81px;
}
.stepWarp .lowerStep .iconWarp {
  top: auto;
  bottom: -152px;
}
@media (max-width: 576px) {
  .stepWarp {
    height: 1238px;
    padding: 60px 10px;
    flex-wrap: wrap;
    overflow: initial;
  }
  .stepWarp .stepLi {
    height: 12.5%;
    width: 50%;
    border-right: 1px solid rgba(138, 136, 137, 0.5);
    border-bottom: none;
  }
  .stepWarp .stepLi .info {
    left: 100%;
    bottom: 100%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  .stepWarp .stepLi .line {
    border-bottom: 2px dotted #C4C4C4;
    width: 26px;
    height: 0;
    left: -10px;
    top: 50%;
    transform: translate(-50%);
  }
  .stepWarp .stepLi .line .sub-circle {
    top: -1.5px;
    left: -8.5px;
  }
  .stepWarp .stepLi .label {
    top: -28px;
    left: -113px;
    font-size: 12px;
  }
  .stepWarp .stepLi .iconWarp {
    top: -3px;
    left: -103px;
  }
  .stepWarp .stepLi:nth-child(1) .info {
    animation: progressLeft 0.5s;
    animation-fill-mode: forwards;
  }
  .stepWarp .stepLi:nth-child(2) .info {
    animation: progressRight 0.5s 0.5s;
    animation-fill-mode: forwards;
  }
  .stepWarp .stepLi:nth-child(3) .info {
    animation: progressLeft 0.5s 1s;
    animation-fill-mode: forwards;
  }
  .stepWarp .stepLi:nth-child(4) .label {
    left: 96px;
  }
  .stepWarp .stepLi:nth-child(4) .info {
    animation: progressRight 0.5s 1.5s;
    animation-fill-mode: forwards;
  }
  .stepWarp .stepLi:nth-child(5) .info {
    animation: progressLeft 0.5s 2s;
    animation-fill-mode: forwards;
  }
  .stepWarp .stepLi:nth-child(6) .info {
    animation: progressRight 0.5s 2.5s;
    animation-fill-mode: forwards;
  }
  .stepWarp .stepLi:nth-child(7) .info {
    animation: progressLeft 0.5s 3s;
    animation-fill-mode: forwards;
  }
  .stepWarp .stepLi:nth-child(8) .info {
    animation: progressRight 0.5s 3.5s;
    animation-fill-mode: forwards;
  }
  .stepWarp .lowerStep {
    transform: rotate(180deg) translateX(1.5px) translateY(-140px);
  }
  .stepWarp .lowerStep .info {
    left: 100%;
    top: 100%;
    transform: translate(-50%, -50%) rotate(-180deg);
    bottom: auto;
  }
  .stepWarp .lowerStep .line {
    left: 75px;
  }
  .stepWarp .lowerStep .line .sub-circle {
    left: calc(100% + 3px);
  }
  .stepWarp .lowerStep .label {
    left: 96px;
  }
  .stepWarp .lowerStep .iconWarp {
    left: 105px;
  }
}
@keyframes step1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes progressLower {
  0% {
    opacity: 0;
    transform: translate(-50%, 50px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 50%);
  }
}
@keyframes progressUpper {
  0% {
    opacity: 0;
    transform: translate(-50%, 50px) rotate(-180deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(-180deg);
  }
}
@keyframes progressLeft {
  0% {
    opacity: 0;
    transform: translate(50px, 50%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 50%);
  }
}
@keyframes progressRight {
  0% {
    opacity: 0;
    transform: translate(50px, -50%) rotate(-180deg);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(-180deg);
  }
}
