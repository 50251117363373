.about-page-header {
  height: 750px;
  width: 100%;
  background-image: url('../../assets/img/about-page/head-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.about-page-header h1 {
  font-size: 3.75rem;
  font-weight: normal;
  color: #000;
  margin: 0;
}
.about-page-header h4 {
  font-size: 1.25rem;
  font-weight: normal;
  color: #000;
  margin: 0;
  margin-top: 5px;
}
@media (max-width: 960px) {
  .about-page-header {
    height: 250px;
  }
  .about-page-header h1 {
    font-size: 1.25rem;
  }
}
.about-page-section {
  padding: 100px 0;
  display: flex;
  max-width: 1380px;
  margin: 0 auto;
  justify-content: space-between;
}
@media (max-width: 960px) {
  .about-page-section {
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.about-page-section-content {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  height: 100%;
  min-height: 513px;
  width: 338px;
  flex-shrink: 0;
}
.about-page-section-content-subtitle {
  color: #6079D2;
  font-size: 1rem;
  white-space: nowrap;
}
.about-page-section-content-title {
  font-size: 2.18rem;
  font-weight: bold;
  margin: 10px 0;
}
.about-page-section-content-describe {
  height: 50px;
  display: flex;
  font-size: 1rem;
  align-items: center;
  color: #000;
}
.about-page-section-content-describe img {
  margin-right: 20px;
  height: 1rem;
}
.about-page-section-content-text {
  color: #444444;
  line-height: 1.8rem;
  min-height: 160px;
  font-size: 1rem;
  white-space: pre-wrap;
  margin-top: 30px;
  letter-spacing: 1px;
}
@media (max-width: 960px) {
  .about-page-section-content {
    width: 270px;
    min-height: unset;
  }
  .about-page-section-content-subtitle {
    font-size: 0.75rem;
  }
  .about-page-section-content-title {
    font-size: 1.25rem;
    margin: 10px 0 10px 0;
  }
  .about-page-section-content-describe {
    font-size: 0.75rem;
    height: auto;
  }
  .about-page-section-content-text {
    font-size: 0.75rem;
    min-height: unset;
    margin-bottom: 10px;
  }
  .about-page-section-content .button-arrow {
    font-size: 0.875rem;
  }
}
.about-page-section-imgs {
  display: flex;
  align-items: flex-end;
  height: 100%;
  position: relative;
  max-width: 622px;
}
@media (max-width: 960px) {
  .about-page-section-imgs {
    order: -1 !important;
    width: auto;
    max-width: unset;
    text-align: center;
  }
}
.about-page-section-imgs img {
  transition: all 0.5s ease;
}
.about-page-section-vine-imgs img:first-child,
.about-page-section-vine-imgs img:last-child {
  height: 414px;
  width: 205px;
  z-index: 2;
}
.about-page-section-vine-imgs img:nth-child(2) {
  height: 487px;
  width: 240px;
  z-index: 3;
}
.about-page-section-vine-imgs img:first-child {
  transform: translateX(58px);
}
.about-page-section-vine-imgs img:last-child {
  transform: translateX(-58px);
}
@media (max-width: 960px) {
  .about-page-section-vine-imgs img:first-child {
    transform: translateX(25px);
  }
  .about-page-section-vine-imgs img:last-child {
    transform: translateX(-25px);
  }
  .about-page-section-vine-imgs img:nth-child(2) {
    width: 102px;
    height: 205px;
  }
  .about-page-section-vine-imgs img:first-child,
  .about-page-section-vine-imgs img:last-child {
    height: 174px;
    width: 86px;
  }
}
.about-page-section-vine-imgs:hover img:first-child {
  transform: rotate(-20deg) translateX(38px);
}
.about-page-section-vine-imgs:hover img:last-child {
  transform: rotate(20deg) translateX(-38px);
}
.about-page-section-moego-content {
  padding-top: 0;
}
@media (max-width: 960px) {
  .about-page-section-moego-content {
    padding-top: 40px;
  }
}
.about-page-section-moego-imgs {
  width: 600px;
  padding-top: 40px;
}
.about-page-section-moego-imgs img:first-child {
  width: 167px;
  z-index: 3;
}
.about-page-section-moego-imgs img:last-child {
  width: 605px;
  z-index: 2;
  transform: translate(-195px, 2px);
}
.about-page-section-moego-imgs:hover img:first-child {
  transform: rotate(-19deg) translate(20px, -2px);
}
@media (max-width: 960px) {
  .about-page-section-moego-imgs {
    padding-top: 0;
    width: auto;
  }
  .about-page-section-moego-imgs img:first-child {
    width: 74px;
    transform: translate(46px, 6px);
  }
  .about-page-section-moego-imgs img:last-child {
    width: 270px;
    transform: translate(-24px, 0px);
  }
}
.about-page-section-harmay-imgs img {
  width: 214px;
  height: 436px;
}
.about-page-section-harmay-imgs img:first-child {
  z-index: 2;
  transform: translate(46px, 80px);
}
.about-page-section-harmay-imgs img:last-child {
  z-index: 3;
}
.about-page-section-harmay-imgs:hover img:first-child {
  transform: translate(-40px, 0);
}
@media (max-width: 960px) {
  .about-page-section-harmay-imgs img {
    width: 94px;
    height: 190px;
  }
  .about-page-section-harmay-imgs img:first-child {
    transform: translate(0, 0);
  }
  .about-page-section-harmay-imgs img:last-child {
    transform: translate(-26px, -26px);
  }
  .about-page-section-harmay-imgs:hover img:first-child {
    transform: translate(0, 0);
  }
  .about-page-section-harmay-imgs:hover img:last-child {
    transform: translate(10px, 0);
  }
}
.about-page-section-cheese-imgs img {
  width: 207px;
  height: 419px;
}
.about-page-section-cheese-imgs img:first-child {
  z-index: 3;
}
.about-page-section-cheese-imgs img:last-child {
  z-index: 2;
  transform: translate(-26px, 70px);
}
.about-page-section-cheese-imgs:hover img:last-child {
  transform: translate(40px, 0);
}
@media (max-width: 960px) {
  .about-page-section-cheese-imgs img {
    width: 100px;
    height: 202px;
  }
  .about-page-section-cheese-imgs img:first-child {
    transform: translate(26px, -26px);
  }
  .about-page-section-cheese-imgs img:last-child {
    transform: translate(0, 0);
  }
  .about-page-section-cheese-imgs:hover img:first-child {
    transform: translate(-10px, 0);
  }
  .about-page-section-cheese-imgs:hover img:last-child {
    transform: translate(0, 0);
  }
}
.about-page-section-carez-imgs img {
  width: 236px;
  height: 466px;
}
.about-page-section-carez-imgs:hover img:last-child {
  transform: rotate(20deg);
}
@media (max-width: 960px) {
  .about-page-section-carez-imgs img {
    width: 108px;
    height: 213px;
  }
}
.about-page-section-qihuibao-content {
  padding-top: 0;
}
@media (max-width: 960px) {
  .about-page-section-qihuibao-content {
    padding-top: 40px;
  }
}
.about-page-section-qihuibao-imgs {
  width: 570px;
  height: 367px;
}
.about-page-section-qihuibao-imgs img {
  position: absolute;
}
.about-page-section-qihuibao-imgs img:first-child {
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 50px;
  left: 0;
}
.about-page-section-qihuibao-imgs img:nth-child(2) {
  width: 185px;
  z-index: 3;
  top: 0;
  right: 41px;
}
.about-page-section-qihuibao-imgs img:last-child {
  width: 185px;
  z-index: 3;
  right: 41px;
  bottom: -60px;
}
.about-page-section-qihuibao-imgs:hover img:nth-child(2) {
  top: -10px;
  right: 192px;
}
.about-page-section-qihuibao-imgs:hover img:last-child {
  right: 192px;
  bottom: -50px;
}
@media (max-width: 960px) {
  .about-page-section-qihuibao-imgs {
    width: 278px;
    height: 164px;
  }
  .about-page-section-qihuibao-imgs img:first-child {
    top: 0;
  }
  .about-page-section-qihuibao-imgs img:nth-child(2) {
    width: 79px;
    top: -20px;
    right: 0;
  }
  .about-page-section-qihuibao-imgs img:last-child {
    width: 79px;
    right: 0;
    bottom: -4px;
  }
  .about-page-section-qihuibao-imgs:hover img:nth-child(2) {
    top: -19px;
    right: 99px;
  }
  .about-page-section-qihuibao-imgs:hover img:last-child {
    right: 99px;
    bottom: -1px;
  }
}
.about-page-section-glasses-imgs {
  width: 632px;
  height: 468px;
}
.about-page-section-glasses-imgs img {
  position: absolute;
}
.about-page-section-glasses-imgs img:first-child {
  width: 632px;
  z-index: 3;
  right: 0;
  bottom: -27px;
}
.about-page-section-glasses-imgs img:last-child {
  width: 216px;
  z-index: 2;
  top: 0;
  right: 0;
}
.about-page-section-glasses-imgs:hover img:last-child {
  transform: rotate(20deg) translate(18px, 34px);
}
@media (max-width: 960px) {
  .about-page-section-glasses-imgs {
    width: 178px;
    height: 215px;
  }
  .about-page-section-glasses-imgs img:first-child {
    width: 260px;
    right: 0;
    bottom: 0;
  }
  .about-page-section-glasses-imgs img:last-child {
    width: 99px;
    top: 0;
    right: 0;
  }
  .about-page-section-glasses-imgs:hover img:last-child {
    transform: rotate(20deg) translate(18px, 34px);
  }
}
.about-page-section-kems-imgs img {
  width: 177px;
}
.about-page-section-kems-imgs img:nth-child(2) {
  z-index: 3;
}
.about-page-section-kems-imgs img:first-child {
  z-index: 2;
  transform: translate(48px, 73px);
}
.about-page-section-kems-imgs img:last-child {
  z-index: 2;
  transform: translate(-48px, 73px);
}
.about-page-section-kems-imgs:hover img:first-child {
  transform: rotate(-20deg) translate(10px, 38px);
}
.about-page-section-kems-imgs:hover img:last-child {
  transform: rotate(20deg) translate(-10px, 38px);
}
@media (max-width: 960px) {
  .about-page-section-kems-imgs img {
    width: 98px;
  }
  .about-page-section-kems-imgs img:nth-child(2) {
    transform: translate(0, -24px);
  }
  .about-page-section-kems-imgs img:first-child {
    transform: translate(24px, 0);
  }
  .about-page-section-kems-imgs img:last-child {
    transform: translate(-24px, 0);
  }
  .about-page-section-kems-imgs:hover img:first-child {
    transform: rotate(-20deg) translate(10px, 0);
  }
  .about-page-section-kems-imgs:hover img:last-child {
    transform: rotate(20deg) translate(-10px, 0);
  }
}
.about-page-section-shop-content {
  padding: 20px 0;
}
@media (max-width: 960px) {
  .about-page-section-shop-content {
    padding: 40px 0;
  }
}
.about-page-section-shop-imgs img {
  width: 239px;
}
.about-page-section-shop-imgs img:last-child {
  margin-left: 20px;
}
.about-page-section-shop-imgs:hover img:first-child {
  transform: rotate(20deg);
}
.about-page-section-shop-imgs:hover img:last-child {
  transform: rotate(20deg) translate(20px);
}
@media (max-width: 960px) {
  .about-page-section-shop-imgs img {
    width: 105px;
  }
  .about-page-section-shop-imgs img:last-child {
    margin-left: 10px;
  }
  .about-page-section-shop-imgs:hover img:first-child {
    transform: rotate(20deg);
  }
  .about-page-section-shop-imgs:hover img:last-child {
    transform: rotate(20deg) translate(10px);
  }
}
.about-page-section-PrismPop-imgs {
  flex-grow: 1;
  height: 420px;
}
.about-page-section-PrismPop-imgs img {
  position: absolute;
}
.about-page-section-PrismPop-imgs img:first-child {
  width: 100%;
  top: 100px;
  left: 0;
}
.about-page-section-PrismPop-imgs img:last-child {
  width: 110px;
  right: 86px;
  bottom: 0;
}
.about-page-section-PrismPop-imgs:hover img:last-child {
  transform: translateY(-36px);
}
@media (max-width: 960px) {
  .about-page-section-PrismPop-imgs {
    width: 358px;
    height: 174px;
  }
  .about-page-section-PrismPop-imgs img:first-child {
    width: 100%;
    top: 0;
    left: 0;
  }
  .about-page-section-PrismPop-imgs img:last-child {
    width: 63px;
    right: 52px;
    bottom: -14px;
  }
  .about-page-section-PrismPop-imgs:hover img:last-child {
    transform: translateY(-26px);
  }
}
.about-page-section-Tripalink-content {
  align-self: center;
}
.about-page-section-Tripalink-imgs img {
  width: 622px;
}
@media (max-width: 960px) {
  .about-page-section-Tripalink-imgs img {
    width: 293px;
  }
}
.about-page-section-Trashaus-imgs img {
  width: 191px;
}
.about-page-section-Trashaus-imgs img:nth-child(2) {
  z-index: 3;
}
.about-page-section-Trashaus-imgs img:first-child {
  z-index: 2;
  transform: translate(48px, 73px);
}
.about-page-section-Trashaus-imgs img:last-child {
  z-index: 2;
  transform: translate(-48px, 73px);
}
.about-page-section-Trashaus-imgs:hover img:first-child {
  transform: rotate(-20deg) translate(5px, 98px);
}
.about-page-section-Trashaus-imgs:hover img:last-child {
  transform: rotate(20deg) translate(-5px, 98px);
}
@media (max-width: 960px) {
  .about-page-section-Trashaus-imgs img {
    width: 103px;
  }
  .about-page-section-Trashaus-imgs img:nth-child(2) {
    transform: translate(0, -22px);
  }
  .about-page-section-Trashaus-imgs img:first-child {
    transform: translate(19px, 0);
  }
  .about-page-section-Trashaus-imgs img:last-child {
    transform: translate(-19px, 0);
  }
  .about-page-section-Trashaus-imgs:hover img:first-child {
    transform: rotate(-20deg) translate(5px, 0);
  }
  .about-page-section-Trashaus-imgs:hover img:last-child {
    transform: rotate(20deg) translate(-5px, 0);
  }
}
.about-page-stripe-img {
  display: block;
  width: 50%;
}
