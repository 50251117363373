.home-page section {
  display: flex;
  justify-content: center;
}
.home-page section .ant-row {
  width: 100%;
  max-width: 1380px;
}
.home-page-stripe-left,
.home-page-stripe-right {
  width: 100%;
}
.home-page-header {
  height: 750px;
  width: 100%;
  background-color: #ffffff;
  padding: 250px 130px;
  position: relative;
  max-width: 1380px;
  margin: 0 auto;
  background-image: url('../../assets/img/home-page/home-header-bg.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
@media (max-width: 576px) {
  .home-page-header {
    height: 600px;
  }
}
@media (max-width: 576px) {
  .home-page-header {
    background-size: auto 100% ;
    background-position-x: 70%;
  }
}
.home-page-header-bg {
  z-index: 1;
  position: relative;
  top: -150px;
  left: -30px;
  width: 1100px;
  margin: 0 auto;
}
@media (max-width: 576px) {
  .home-page-header-bg {
    display: none;
  }
}
.home-page-header-bg .stagger-visualizer {
  position: absolute;
  top: 0;
  left: 0;
  width: 1100px;
  height: 550px;
  transform-origin: left top;
}
.home-page-header-bg .stagger-visualizer .dots-wrapper {
  transform: translateZ(0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.home-page-header-bg .stagger-visualizer .dot {
  position: relative;
  z-index: 1;
  width: 23px;
  height: 23px;
  margin: 16px;
  background-color: currentColor;
  border-radius: 50%;
}
.home-page-header-bg .stagger-visualizer .dot {
  background-color: transparent;
  background-image: linear-gradient(180deg, #d4c1db2c 8%, #c1c4dd1c 100%);
}
.home-page-header-bgmobile {
  z-index: 1;
  position: relative;
  top: -125px;
  left: -20px;
  width: 350px;
  margin: 0 auto;
  display: none;
}
@media (max-width: 576px) {
  .home-page-header-bgmobile {
    display: block;
  }
}
.home-page-header-bgmobile .stagger-visualizer-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 350px;
  height: 550px;
  transform-origin: left top;
}
.home-page-header-bgmobile .stagger-visualizer-mobile .dots-wrapper {
  transform: translateZ(0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.home-page-header-bgmobile .stagger-visualizer-mobile .dot {
  position: relative;
  z-index: 1;
  width: 23px;
  height: 23px;
  margin: 16px;
  background-color: currentColor;
  border-radius: 50%;
}
.home-page-header-bgmobile .stagger-visualizer-mobile .dot {
  background-color: transparent;
  background-image: linear-gradient(180deg, #ffffff7c 8%, #d3cdc68c 100%);
}
.home-page-header-text {
  z-index: 2;
  position: absolute;
  left: 10vw;
}
.home-page-header-text h4 {
  font-size: 1.5rem;
  color: #ffffff;
}
.home-page-header-text h2 {
  font-size: 2.2rem;
  color: #ffffff;
}
.home-page-header-text h1 {
  font-size: 4.7rem;
  color: #ffffff;
}
.home-page-header-consult-btn {
  z-index: 2;
  position: absolute;
  left: 10vw;
  margin-top: 113px;
  color: #ffffff;
  top: 450px;
}
@media (max-width: 576px) {
  .home-page-header {
    padding: 150px 33px;
  }
  .home-page-header-text {
    z-index: 2;
    position: absolute;
    left: 10vw;
  }
  .home-page-header-text h4 {
    font-size: 1.2rem;
    color: #ffffff;
  }
  .home-page-header-text h2 {
    font-size: 1.6rem;
    color: #ffffff;
  }
  .home-page-header-text h1 {
    font-size: 2.7rem;
    color: #ffffff;
  }
  .home-page-header-consult-btn {
    margin-top: 83px;
  }
}
.home-page-radpad {
  padding: 100px 0;
  background-color: #F8F8F9;
}
.home-page-radpad-left {
  max-width: 400px;
}
.home-page-radpad-left-subtitle {
  color: #6079D2;
  font-size: 1rem;
}
.home-page-radpad-left-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.home-page-radpad-left-describe {
  height: 50px;
  display: flex;
  font-size: 1rem;
  align-items: center;
}
.home-page-radpad-left-describe img {
  margin-right: 20px;
  height: 1rem;
}
.home-page-radpad-left-text {
  color: #444444;
  line-height: 1.7rem;
}
.home-page-radpad-left-detail-btn {
  font-size: 1rem;
  border-radius: 18px;
  margin-top: 23px;
}
@media (max-width: 576px) {
  .home-page-radpad-left-text,
  .home-page-radpad-left-detail-btn {
    display: none;
  }
}
.home-page-radpad-right-img img {
  position: absolute;
  display: flex;
  bottom: 0;
}
.home-page-radpad-right-img-left {
  z-index: 2;
  height: 22rem;
  transition: all 0.5s ease;
}
.home-page-radpad-right-img-center {
  z-index: 3;
  left: 125px;
  height: 26rem;
}
.home-page-radpad-right-img-right {
  z-index: 2;
  left: 290px;
  height: 22rem;
  transition: all 0.5s ease;
}
.home-page-radpad-right-img:hover .home-page-radpad-right-img-left {
  transform: rotate(-25deg) translateX(25px);
  transition: all 0.5s ease;
}
.home-page-radpad-right-img:hover .home-page-radpad-right-img-right {
  transform: rotate(25deg) translateX(-25px);
  transition: all 0.5s ease;
}
@media (max-width: 576px) {
  .home-page-radpad-right {
    height: 320px;
  }
  .home-page-radpad-right-img-left {
    width: 25vw;
    height: auto;
    left: 12vw;
  }
  .home-page-radpad-right-img-center {
    width: 35vw;
    height: auto;
    left: 32vw;
  }
  .home-page-radpad-right-img-right {
    width: 25vw;
    height: auto;
    left: 62vw;
  }
}
.home-page-radpad-mobile {
  display: none;
  padding: 20px 15vw;
}
@media (max-width: 576px) {
  .home-page-radpad-mobile {
    display: block;
  }
}
.home-page-radpad-mobile-text {
  color: #444444;
  line-height: 1.7rem;
}
.home-page-radpad-mobile-detail-btn {
  margin-top: 23px;
  color: #ffffff;
}
.home-page-case {
  text-align: center;
  background-color: #F8F8F9;
  flex-wrap: wrap;
}
.home-page-case-more-btn {
  width: 100%;
  display: inline-block;
  font-size: 1rem;
  margin-top: 0 auto;
  color: #ffffff;
  cursor: pointer;
  margin-top: 40px;
}
@media (max-width: 576px) {
  .home-page-case-more-btn {
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
.home-page-advantage {
  padding: 100px 0;
  background-color: #ffffff;
  background-image: url('../../assets/img/home-page/advantage-bg.png');
  background-size: auto 100%;
  background-position: 20% 20px;
  background-repeat: no-repeat;
}
.home-page-advantage-left {
  max-width: 500px;
}
.home-page-advantage-left-subtitle {
  color: #6079D2;
  font-size: 1rem;
}
.home-page-advantage-left-title {
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 20px;
}
.home-page-advantage-left-text {
  color: #444444;
  line-height: 1.7rem;
}
.home-page-advantage-left-detail-btn {
  margin-top: 23px;
  color: #ffffff;
  cursor: pointer;
}
.home-page-development {
  padding: 100px 0;
  background-color: #ffffff;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
.home-page-development-left {
  max-width: 500px;
}
.home-page-development-left-subtitle {
  color: #6079D2;
  font-size: 1rem;
}
.home-page-development-left-title {
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 20px;
}
.home-page-development-left-text {
  color: #444444;
  line-height: 1.7rem;
}
.home-page-aboutus {
  height: 750px;
  width: 100%;
  background-image: url('../../assets/img/home-page/our-team-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ffffff;
  padding: 230px 0px;
}
@media (max-width: 576px) {
  .home-page-aboutus {
    height: 1000px;
  }
}
.home-page-aboutus-left {
  max-width: 400px;
  color: #ffffff;
}
.home-page-aboutus-left-subtitle {
  color: #6079D2;
  font-size: 1rem;
}
.home-page-aboutus-left-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 20px;
}
.home-page-aboutus-left-describe {
  height: 50px;
  display: flex;
  font-size: 1rem;
  align-items: center;
}
.home-page-aboutus-left-describe img {
  margin-right: 20px;
  height: 1rem;
}
.home-page-aboutus-left-text {
  line-height: 1.7rem;
}
.home-page-aboutus-left-more-btn {
  margin-top: 33px;
  color: #ffffff;
  cursor: pointer;
}
@media (max-width: 576px) {
  .home-page-aboutus-left-text,
  .home-page-aboutus-left-more-btn {
    display: none;
  }
}
.home-page-aboutus-right {
  color: #ffffff;
  position: relative;
  top: -40px;
}
@media (max-width: 576px) {
  .home-page-aboutus-right {
    text-align: center;
    top: 0px;
  }
}
.home-page-aboutus-right-count {
  font-size: 2.5rem;
  margin-top: 30px;
}
@media (max-width: 576px) {
  .home-page-aboutus-right-count {
    font-size: 2rem;
  }
}
.home-page-aboutus-right-title {
  font-size: 1rem;
  line-height: 2rem;
}
.home-page-aboutus-right-text {
  display: none;
}
@media (max-width: 576px) {
  .home-page-aboutus-right-text {
    display: block;
    margin-top: 50px;
    line-height: 1.8rem;
  }
}
.home-page-aboutus-right-more-btn {
  display: none;
  margin-top: 33px;
  color: #ffffff;
  cursor: pointer;
}
@media (max-width: 576px) {
  .home-page-aboutus-right-more-btn {
    display: inline-block;
  }
}
.home-page-clients {
  padding: 150px 0;
}
.home-page-clients-subtitle {
  color: #8A8889;
}
.home-page-clients-title {
  color: #36454C;
  font-size: 1.4rem;
  line-height: 50px;
  margin-bottom: 80px;
}
@media (max-width: 576px) {
  .home-page-clients-title {
    margin-bottom: 10px;
  }
}
.home-page-clients-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home-page-clients-list-item {
  opacity: 0.5;
  width: 18%;
  margin-right: 2%;
  margin-bottom: 80px;
  transition: all 0.3s;
}
.home-page-clients-list-item img {
  width: 90%;
}
@media (max-width: 576px) {
  .home-page-clients-list-item {
    width: 50%;
    height: 6vh;
    display: flex;
    align-items: center;
    margin-right: 0%;
  }
  .home-page-clients-list-item img {
    width: 60%;
  }
}
@media (max-width: 576px) {
  .home-page-clients-list-item:nth-child(even) {
    text-align: right;
    justify-content: flex-end;
  }
}
.home-page-clients-list-item:hover {
  opacity: 1;
}
.home-page-consult-left {
  padding-top: 50px;
}
@media (max-width: 576px) {
  .home-page-consult-left {
    text-align: center;
  }
}
.home-page-consult-left-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.home-page-consult-left-text {
  color: #444444;
  line-height: 1.7rem;
}
.home-page-consult-left-consult-btn {
  margin-top: 53px;
}
@media (max-width: 576px) {
  .home-page-consult-left-consult-btn {
    margin: 40px auto;
  }
}
@media (max-width: 576px) {
  .home-page-consult-right {
    display: none;
  }
}
.home-page-map,
.home-page-mobile-map {
  width: 100%;
}
.home-page-mobile-map {
  display: none;
}
@media (max-width: 576px) {
  .home-page-map {
    display: none;
  }
  .home-page-mobile-map {
    display: block;
  }
}
.home-page-footer {
  background: #0F2137;
  color: #ffffff;
  padding: 50px 0;
}
@media (max-width: 576px) {
  .home-page-footer-contacts {
    text-align: center;
  }
}
.home-page-footer-contacts-item {
  line-height: 1.8rem;
  display: block;
  padding-left: 50px;
}
@media (max-width: 576px) {
  .home-page-footer-contacts-item {
    justify-content: center;
    padding-left: 0px;
  }
}
.home-page-footer-contacts-item img {
  margin-right: 10px;
}
.home-page-footer-navs {
  padding-top: 70px;
}
@media (max-width: 576px) {
  .home-page-footer-navs {
    text-align: center;
  }
}
.home-page-footer-navs div {
  text-decoration: underline;
  margin-bottom: 20px;
}
.home-page-footer-navs div:hover {
  cursor: pointer;
}
@media (max-width: 576px) {
  .home-page-footer-addresses {
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
.home-page-footer-addresses-item {
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .home-page-footer-addresses-item {
    text-align: center;
  }
}
.home-page-footer-addresses-item-logo {
  padding: 0 20px;
}
.home-page-footer-addresses-item-logo img {
  width: 80%;
}
.home-page-footer-addresses-item-city {
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 0.4rem;
}
.home-page-footer-addresses-item-content {
  display: flex;
  line-height: 1.8rem;
}
@media (max-width: 576px) {
  .home-page-footer-addresses-item-content {
    justify-content: center;
  }
}
.home-page-footer-addresses-item-content img {
  height: 1rem;
  position: relative;
  top: 0.4rem;
  margin-right: 10px;
}
.home-page-footer-addresses-item:nth-child(3) {
  margin-bottom: 0;
}
.home-page-beian {
  background-color: #000000;
  color: #ffffff;
  padding: 10px 0;
}
.home-page-beian-company {
  text-align: right;
}
@media (max-width: 576px) {
  .home-page-beian-company {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .home-page-beian-icpnumber {
    text-align: center;
    position: relative;
    right: 50px;
  }
}
.home-page .text-editor-wrap {
  display: block;
  margin: auto;
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 20px 70px;
  clear: both;
  overflow: hidden;
  transition: all 0.5s ease-out;
}
@media (max-width: 576px) {
  .home-page .text-editor-wrap {
    margin-top: 50px;
  }
}
.home-page .text-editor-wrap .title-bar {
  padding: 5px 0;
  font-family: "Lucida Grande", sans-serif;
  font-size: 0.75em;
  text-align: center;
  text-shadow: rgba(255, 255, 255, 0.8) 0px 1px 0px;
  background-color: #f8f8f8;
  background-image: -webkit-linear-gradient(top, #e8e8e8, #bcbbbc);
  background-image: -moz-linear-gradient(top, #e8e8e8, #bcbbbc);
  background-image: linear-gradient(top, #e8e8e8, #bcbbbc);
  box-shadow: inset rgba(255, 255, 255, 0.7) 0px 1px 1px;
  border-bottom: #6a6a6a 1px solid;
}
.home-page .text-editor-wrap .title-bar .title {
  padding: 5px 0;
  font-family: "Lucida Grande", sans-serif;
  font-size: 0.75em;
  text-align: center;
  text-shadow: rgba(255, 255, 255, 0.8) 0px 1px 0px;
}
.home-page .text-editor-wrap .text-body {
  height: 250px;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 10px;
  color: #f0f0f0;
  text-shadow: #000 0px 1px 0px;
  font-family: "Consolas", "Courier New", "Courier";
  font-size: 1.45em;
  line-height: 1.4em;
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  transition: all 0.5s ease-out;
}
.home-page .coreTeam-section {
  transition: all 0.6s;
  overflow: hidden;
}
.home-page .coreTeam-section .pack-up {
  margin-top: 30px;
  text-align: center;
}
.home-page .coreTeam-section .pack-up .anticon {
  font-size: 20px;
  cursor: pointer;
}
.home-page .coreTeam-section .pack-up {
  margin-top: 60px;
}
.home-page-footer-line {
  width: 50%;
  height: 30px;
  margin-top: 160px;
  position: relative;
}
.home-page-footer-line::before {
  content: '';
  display: block;
  width: calc(100% - 20px);
  height: 100%;
  position: absolute;
  top: 15px;
  left: 0;
  background-color: #6079D2;
}
.home-page-footer-line::after {
  content: '';
  display: block;
  width: 212px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -40px;
  background-color: #4ED1E0;
}
@media (max-width: 960px) {
  .home-page-footer-line {
    margin-top: 45px;
  }
  .home-page-footer-line::before {
    display: none;
  }
  .home-page-footer-line::after {
    display: none;
  }
}
