.projects {
  overflow: hidden;
  padding-bottom: 120px;
}
.projects-cases {
  min-height: 570px;
  margin-bottom: 40px;
}
@media (max-width: 960px) {
  .projects-cases {
    margin-bottom: 28px;
  }
  .projects-cases:last-child {
    margin-bottom: 96px;
  }
  .projects-cases:last-child .projects-cases-container-description-line {
    display: none;
  }
  .projects-cases:last-child .projects-cases-container-description-circle {
    display: none;
  }
}
.projects-cases-container-title {
  font-weight: bold;
  font-size: 2.18rem;
  margin-bottom: 26px;
  text-align: center;
}
.projects-cases-container-subtitle {
  color: #6079D2;
  font-size: 1rem;
  text-align: center;
}
@media (max-width: 960px) {
  .projects-cases-container {
    width: 282px;
    margin: 0 auto;
  }
  .projects-cases-container-title {
    font-size: 1.25rem;
    margin-bottom: 16px;
    text-align: left;
  }
  .projects-cases-container-subtitle {
    font-size: 0.75rem;
    text-align: left;
  }
}
.projects-cases-container-description {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 20px;
}
@media (max-width: 960px) {
  .projects-cases-container-description {
    flex-direction: column;
    padding-bottom: 0;
  }
}
.projects-cases-container-description-circle {
  display: block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: -26px;
  margin-left: -17px;
}
.projects-cases-container-description-circle img {
  width: 100%;
  height: 100%;
}
@media (max-width: 960px) {
  .projects-cases-container-description-circle {
    width: 25px;
    height: 25px;
    margin-left: -9px;
    top: unset;
    left: -25px;
    bottom: -27px;
  }
}
.projects-cases-container-description-line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: #8a635186;
  position: absolute;
  left: 50%;
  top: 15px;
}
@media (max-width: 960px) {
  .projects-cases-container-description-line {
    height: 223px;
    left: -25px;
    top: unset;
    bottom: calc(-223px - 26px);
  }
}
.projects-cases-container-description-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 63px;
}
@media (max-width: 1380px) {
  .projects-cases-container-description-content {
    width: 40%;
  }
}
@media (max-width: 960px) {
  .projects-cases-container-description-content {
    width: 100%;
    padding-right: 0;
    padding-left: 0 !important;
    align-items: flex-start;
  }
}
.projects-cases-container-description-content-tags > span:first-child img {
  width: 19px;
  height: 17px;
  transform: translateY(-2px);
}
.projects-cases-container-description-content-tags > span:first-child img + img {
  margin-left: 14px;
}
.projects-cases-container-description-content-tags > span:last-child {
  font-size: 1rem;
  margin-left: 33px;
}
@media (max-width: 960px) {
  .projects-cases-container-description-content-tags > span:first-child img {
    width: 14px;
    height: 12px;
  }
  .projects-cases-container-description-content-tags > span:last-child {
    font-size: 0.75rem;
    margin-left: 20px;
  }
}
.projects-cases-container-description-content-background {
  max-width: 350px;
  font-size: 1rem;
  color: #757575;
  line-height: 1.875rem;
  margin-top: 30px;
}
@media (max-width: 960px) {
  .projects-cases-container-description-content-background {
    font-size: 0.75rem;
    line-height: 1.625rem;
  }
}
@media (max-width: 960px) {
  .projects-cases-container-description-content-background-0 {
    margin-top: 273px;
  }
}
.projects-cases-container-description-requiredImg {
  width: 50%;
  height: 50%;
  padding-left: 73px;
}
@media (max-width: 1380px) {
  .projects-cases-container-description-requiredImg {
    width: 40%;
  }
}
@media (max-width: 960px) {
  .projects-cases-container-description-requiredImg {
    width: 278px;
    height: 204px;
    position: absolute;
    top: 46px;
    left: 2px;
    padding-left: 0;
    padding-right: 0 !important;
  }
}
.projects-cases-container-description-requiredImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.projects > .projects-cases:nth-child(even) .projects-cases-container-description-content {
  align-items: flex-start;
  padding-right: 0;
  padding-left: 63px;
}
.projects > .projects-cases:nth-child(even) .projects-cases-container-description-requiredImg {
  order: -1;
  padding-left: 0;
  padding-right: 63px;
}
